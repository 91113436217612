import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useSearchParams  } from 'react-router-dom';

import User from '../class/User'

import { Header, Footer } from '../components/imports'

import { APP_NAME, AVATAR_ROOT } from '../constants/general'

import { Context } from '../class/Context'

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

const userGetInfo = async (setUser, id, navigate) => {

    let user = await User.userInfos(id)

    if (user.success) {

        setUser(user.data)

        document.title = user.data.nom + " " + user.data.prenom + " - " + APP_NAME

    } else {

        navigate('/404')

    }

}

function Abonnement() {

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const [stripeInfos, setStripeInfos] = useState(false)
    const [myInfos, setMyInfos] = useState(false)

    const [userAllInfos, setUserAllInfos] = useState(false)

    const [me, setMe] = useState(false)

    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const navigate = useNavigate();

    const context = useContext(Context)
    
    const [searchParams, setSearchParams] = useSearchParams()
    const register_variable = searchParams.get("register")

    useEffect(() => {

        //User.getInfos(setLoading, navigate, context, setMyInfos)

        User.getStripePortal(setStripeInfos)

        User.getAllUserInfos(setUserAllInfos)

        setMe(context.me)

        document.title = "Abonnement - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-lg-8 mb-4 mb-lg-0">
                            <div className="card mb-3">
                                <div>
                                    <div className="">
                                        <div className="card-body p-4">
                                            <h6 className="mb-3 fs-4">Mon abonnement sur Zawaj Muslims</h6>

                                            <div className="mb-5">

                                                {

                                                    register_variable

                                                    &&

                                                    <p className='alert alert-success'>Félicitations, vous avez finalisé votre inscription sur Zawaj Muslims, il ne vous reste que de payer votre abonnement pour commencer à utiliser nos services.</p>

                                                }

                                                {

                                                    stripeInfos

                                                    ?

                                                        stripeInfos.abonnement_stt

                                                        ?

                                                            <div>

                                                                <p className='alert alert-info'>Vous êtes abonné sur { APP_NAME }, Votre abonnement expire le <strong>{formatDate(stripeInfos.abonnement)}</strong>.</p>

                                                                <a href={stripeInfos.portal_url} className="btn btn-primary btn-sm">Gérer mon abonnement</a>

                                                            </div>

                                                        :

                                                            <div>

                                                                <p className='alert alert-danger'>Vous n'êtes pas abonné sur Zawaj Muslims, Abonnez vous pour pouvoir utiliser la messagerie et contactez d'autres utilisateurs</p>

                                                                <ul>
                                                                    <li>Consulter les profiles de tous les utilisateurs en illimité</li>
                                                                    <li>Envoyer et reçevoir des messages.</li>
                                                                </ul>

                                                                <a href={"https://buy.stripe.com/9AQeYs9hzbpo64M003?prefilled_email=" + userAllInfos.email + "&client_reference_id=" + userAllInfos.idU} className="btn btn-primary btn-sm">Payer un abonnement (7.99€ par mois)</a>

                                                            </div>
                                                    
                                                    :

                                                        <div className="d-flex justify-content-center my-5 py-xl-5">
                                                            <div className="spinner-border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>

                                                }

                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Abonnement