import React, { useState, useContext } from 'react';

import { Context } from '../class/Context'

import { useNavigate, Link } from "react-router-dom";

import { WEBROOT, APP_NAME } from '../constants/general';

import { Logout } from '../class/Func'

const Header = () => {

    const context = useContext(Context)

    const navigate = useNavigate()

    return (
        <header className="bg-danger">
            <div className='mx-auto col-10 d-flex flex-wrap justify-content-center py-3'>
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <img src={ WEBROOT + "image/Salafi_Zawaj_LOGO.png" } className="w-50 m0auto" alt={APP_NAME} />
                </a>

                {
                    (!context.session) ?

                    <ul className="nav nav-pills">
                        <li className="nav-item"><a href="/" className="nav-link text-white">Accueil</a></li>
                        <li className="nav-item"><a href="/inscription" className="nav-link border border-primary border-white text-white">Créer un compte</a></li>
                        <li className="nav-item ms-2"><a href="/connexion" className="nav-link border border-primary border-white text-white">Connexion</a></li>
                    </ul>

                    :

                    <ul className="nav nav-pills">
                        <li className="nav-item home_100"><Link to="/" className="nav-link text-white">Accueil</Link></li>
                        <li className="nav-item ms-2" title="Paramètres de mon compte"><Link to="/parametres" className="nav-link text-white"><i className="bi bi-gear-fill"></i></Link></li>
                        <li className="nav-item ms-2"><Link to="/acceuil" className="nav-link border border-primary border-white text-white"><i className="bi bi-person-fill"></i> Espace membre</Link></li>
                        <li className="nav-item ms-2" onClick={() => Logout(navigate, context, '')}><span className="nav-link bg-danger border border-danger border-danger text-white"><i className="bi bi-x-circle-fill"></i> Déconnexion</span></li>
                    </ul>

                }

            </div>
        </header>
    );

}

const Footer = () => {

    return (
        <footer className="bg-danger">
            <div className='col-10 mx-auto d-flex flex-wrap justify-content-between align-items-center py-3 mt-4'>
                <div className="col-md-4 d-flex align-items-center">
                    <a href="/" className="text-white text-decoration-none">
                        © 2025 Zawaj Mouslims
                    </a>
                </div>

                <ul className="nav col-4 justify-content-end list-unstyled d-flex fs-4">
                    <li><a className="nav-link text-white" href="https://www.instagram.com/"><i className="bi-instagram"></i></a></li>
                    <li><a className="nav-link text-white" href="https://www.facebook.com/"><i className="bi-facebook"></i></a></li>
                </ul>
            </div>
        </footer>
    );

}

export { Header, Footer };